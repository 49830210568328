body {
  margin: 0;
  font-family: Lato !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #222;
  overflow-y: scroll;
}

.nav-link:hover {
  background-color: gray !important;
}

.build-explorer-nav-link {
  list-style-type: none;
}

#col-3 {
  padding-left: 20px;
  padding-right: 20px;
}

#col-9 {
  padding-left: 20px;
  padding-right: 20px;
}

/* General Page */
.lock_top {
  background-color: #222;
}

hr {
  border-color: #AAAAAA;
}

.td-doc-key {
  width: 150px;
}

.table {
  max-width: 1275px;
  border: 2px #606060 solid;
}

.status {
  min-height: 650px;
}

.jumbotron {
  padding: 2rem;
  background-color: #303030;
}

/* use local google font due gpdr */
/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('/public/fonts/lato-latin-ext-i400.woff2') format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/public/fonts/lato-latin-i400.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/public/fonts/lato-latin-ext-n400.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/public/fonts/lato-latin-n400.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/public/fonts/lato-latin-ext-n700.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/public/fonts/lato-latin-n700.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.md5 {
  font-family: 'Space Mono', monospace;
  font-style: normal;
  font-weight: 400;
  src: url(/public/fonts/spacemono-n400.ttf) format("ttf");
}

.commit {
  font-family: 'Space Mono', monospace;
  font-style: normal;
  font-weight: 400;
  src: url(/public/fonts/spacemono-n400.ttf) format("ttf");
}